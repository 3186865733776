<template>
  <el-container class="page-container custom-el-style">
    <slot name="breadcrumb" />

    <el-header v-if="isShowHeader" height="51px">
      <div>
        <div class="title">{{ title }}</div>
      </div>
      <slot name="search" />
    </el-header>
    <el-main>
      <slot name="default" />
    </el-main>
    <el-footer v-if="$slots.footer">
      <slot name="footer" />
    </el-footer>


    <footer class="fixed-footer custom-el-style" :class="{active: shouldShowFixedFooter}">
      <slot name="footer" />
    </footer>
  </el-container>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      shouldShowFixedFooter: false
    };
  },
  computed: {
    /** @returns {boolean} */
    isShowHeader() {
      return this.title;
    }
  },
  mounted() {
    if (this.$slots.footer) {
      this.initFixedFooter();
    }
  },
  methods: {
    initFixedFooter() {
      let footerEl = this.$slots.footer[0].elm;
      new IntersectionObserver(([entry]) => {
        this.shouldShowFixedFooter = entry.intersectionRatio <= 0;
      }).observe(footerEl);
    }
  }
};
</script>

<style scoped>
  .page-container{
    display: block;
    width:100%;
    height: auto;
    box-sizing: border-box;
    background: #ffffff;
    /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05); */
    /* border-radius: 10px; */
    padding-bottom: 20px;
  }
  .el-header {
    padding: 0;
    height: 51px;
    position: relative;
    top: 3px;
    display: flex;
    justify-content: space-between
  }
   .title{
    background: #ffffff;
    box-sizing: border-box;
    display: inline-block;
    flex: 1;
  }
  .el-header{
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    border-bottom: solid 1px var(--split-line-color);
  }
  .el-main{
    padding: 16px 16px 0 16px !important;
  }
  .el-breadcrumb__item{
    font-size: 12px;
  }
  .el-breadcrumb__item >>> .el-breadcrumb__inner {
    color: var(--text3-color);
  }
  .el-breadcrumb__item >>> .el-breadcrumb__inner.is-link {
    font-weight: normal;
  }
  .el-breadcrumb__item:last-child >>> .el-breadcrumb__inner{
    color: var(--text1-color);
  }

  .el-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding-left: 0; */
    padding-top: 19px;
    border-top: 1px solid #F1F1F1;
  }
  .fixed-footer {
    position: fixed;
    /* 定位方式需要优化 */
    left: var(--theme-nav-width) ;
    right: 20px;
    bottom: 0;
    height: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: .3s;
    box-shadow: 0px -2px 6px rgba(0,0,0,0.06);
    z-index: 100;
  }
  .fixed-footer.active{
    transform: translateY(0);
  }
</style>
