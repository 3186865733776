<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <div style="margin-left: 55px;">
      <el-form inline :model="searchInfo" ref="searchInfo" size="small">
        <el-form-item label-width="100px" label="配置站点：">
          <el-select @change="select" v-model="dapp_port_type" placeholder="请选择授权站点">
            <el-option-group
              v-for="dapp in dapp_port_types"
              style="width: 280px !important"
              :key="dapp.label"
              :label="dapp.label"
            >
              <el-option
                style="width: 280px !important"
                v-for="item in dapp.options"
                :key="item.dapp_id"
                :label="item.dapp_domain+'（'+item.dapp_name+'）'"
                :value="item.dapp_id"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <page-container
      v-if="show"
      is-show-breadcrumbs
      style="padding-bottom: 24px"
      class="custom-el-style"
    >
      <el-form
        :model="formData"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <div>
          <el-form-item label="顶部通栏banner" />
          <el-form-item label="是否打开：" label-width="100px" prop="isEnable">
            <el-switch v-model="formData.top.isEnable" />
          </el-form-item>
          <el-form-item label="显示位置：" label-width="100px" prop="check">
            <el-checkbox-group v-model="formData.top.location">
              <el-checkbox label="homePage">首页</el-checkbox>
              <el-checkbox label="innerPage">内页</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="背景色：" label-width="100px">
            <el-color-picker v-model="formData.top.bgColor" />
          </el-form-item>
          <el-form-item label="文字色：" label-width="100px">
            <el-color-picker v-model="formData.top.textColor" />
          </el-form-item>
          <div class="banner_box">
            <img src="../../images/sketch_map.png" alt="">
            <div
              v-if="formData.top.bgColor"
              class="banner"
              :style="{
                background: formData.top.bgColor,
                color: formData.top.textColor,
              }"
            >
              顶部通栏
            </div>
          </div>
        </div>
        <div>
          <el-form-item label="左侧侧边栏挂件" />
          <el-form-item label="是否打开：" label-width="100px">
            <el-switch v-model="formData.left.isEnable" />
          </el-form-item>
          <el-form-item label="显示位置：" label-width="100px">
            <el-checkbox-group v-model="formData.left.location">
              <el-checkbox label="homePage">首页</el-checkbox>
              <el-checkbox label="innerPage">内页</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="背景色：" label-width="100px">
            <el-color-picker v-model="formData.left.bgColor" />
          </el-form-item>
          <el-form-item label="文字色：" label-width="100px">
            <el-color-picker v-model="formData.left.textColor" />
          </el-form-item>
          <div class="left_box">
            <img src="../../images/sketch_map.png" alt="">
            <div
              v-if="formData.left.bgColor"
              class="left"
              :style="{
                background: formData.left.bgColor,
                color: formData.left.textColor,
              }"
            >
              左侧侧边栏
            </div>
          </div>
        </div>
        <div>
          <el-form-item label="网页底部" />
          <el-form-item label="是否打开：" label-width="100px">
            <el-switch v-model="formData.bottom.isEnable" />
          </el-form-item>
          <el-form-item label="显示位置：" label-width="100px">
            <el-checkbox-group v-model="formData.bottom.location">
              <el-checkbox label="homePage">首页</el-checkbox>
              <el-checkbox label="innerPage">内页</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="背景色：" label-width="100px">
            <el-color-picker v-model="formData.bottom.bgColor" />
          </el-form-item>
          <el-form-item label="文字色：" label-width="100px">
            <el-color-picker v-model="formData.bottom.textColor" />
          </el-form-item>
          <div class="footer_box">
            <img src="../../images/sketch_map.png" alt="">
            <div
              v-if="formData.bottom.bgColor"
              class="footer"
              :style="{
                background: formData.bottom.bgColor,
                color: formData.bottom.textColor,
              }"
            >
              网页底部
            </div>
          </div>
        </div>
      </el-form>
    </page-container>
    <el-footer v-if="show">
      <el-button type="primary" @click="submit">保存</el-button>
    </el-footer>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import { webSiteApi } from "@/services/api";
export default {
  components: {
    PageContainer
  },
  data() {
    return {
      dapp_port_type: "",
      dapp_port_types: [{
        label: "官网站点",
        options: []
      }, {
        label: "商城站点",
        options: []
      }],
      searchInfo: {},
      show: false,
      dapp_id: "",
      formData: {
        top: {
          isEnable: false,
          location: [],
          bgColor: "",
          textColor: ""
        },
        left: {
          isEnable: false,
          location: [],
          bgColor: "",
          textColor: ""
        },
        bottom: {
          isEnable: false,
          location: [],
          bgColor: "",
          textColor: ""
        }
      }
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      let { data } = await webSiteApi.getAll({ name_is_not_empty: 1 });
      this.dapp_port_types[0].options = data.official;
      this.dapp_port_types[1].options = data.shop;
    },
    async select(dapp_id) {
      console.log(dapp_id);
      this.dapp_id = dapp_id;
      this.show = true;
      let { data } = await webSiteApi.getElementConfig({ dapp_id });
      console.log(data);
      if (data) {
        this.formData = { ...data };
      }
    },
    async submit() {
      for (const key in this.formData) {
        console.log(this.formData[key]);
        for (const name in this.formData[key]) {
          if (this.formData[key][name] == null) {
            this.formData[key][name] = "";
          }
        }
      }
      console.log(this.formData);
      let { code } = await webSiteApi.setElementConfig({
        value: this.formData,
        dapp_id: this.dapp_id
      });
      if (code == 200) {
        this.$message.success("设置成功");
        // this.$router.push("/wabpage/site");
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-header {
  display: flex;
  align-items: center;
}
// /deep/.el-input .el-input__inner {
//   width: 50% !important;
// }
.el-button {
  margin-left: 150px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/ .el-select .el-input {
  width: 280px !important;
}
/deep/ .el-select {
  width: 280px !important;
}
.el-select-group__wrap:not(:last-of-type) {
  padding-bottom: 0;
}
.el-select-group__wrap:not(:last-of-type)::after {
  display: none;
}
.demo-ruleForm {
  display: flex;
  justify-content: space-around;
}
.banner_box {
  position: relative;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 100%;
  text-align: center;
}
.left_box {
  position: relative;
}
.left {
  position: absolute;
  top: 50%;
  left: 0;
  width: 48px;
  height: 149px;
  writing-mode: vertical-lr;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_box {
  position: relative;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 23px;
  width: 100%;
  text-align: center;
}
</style>
